import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export default function CateringsSection() {
  const { t } = useTranslation('homepage');
  const mobile = useBreakpointDown('sm');

  if (mobile) return null;

  return (
    <Wrapper>
      <Box>
        <Title>{t('cateringsSection.categories')}</Title>
        <Container>
          <FlexColumn>
            <Link
              href={'https://www.heycater.com/de/fingerfood'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.fingerFood')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/fruehstueck'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.breakfast')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/food-truck'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.foodTruck')}</Subtitle>
            </Link>
            <Link href={'https://www.heycater.com/de/events'} target="_blank">
              <Subtitle>{t('cateringsSection.events')}</Subtitle>
            </Link>
          </FlexColumn>
          <FlexColumn>
            <Link href={'https://www.heycater.com/de/buffet'} target="_blank">
              <Subtitle>{t('cateringsSection.buffet')}</Subtitle>
            </Link>
            <Link href={'https://www.heycater.com/de/vegan'} target="_blank">
              <Subtitle>{t('cateringsSection.vegan')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/grill-catering'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.grill')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/sommerfest'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.summerParty')}</Subtitle>
            </Link>
          </FlexColumn>
        </Container>
      </Box>
      <Box>
        <Title>{t('cateringsSection.city')}</Title>
        <Container>
          <FlexColumn>
            <Link
              href={'https://www.heycater.com/de/catering-berlin'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.berlin')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/catering-muenchen'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.munich')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/catering-hamburg'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.hamburg')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/catering-frankfurt-am-main'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.frankfurt')}</Subtitle>
            </Link>
          </FlexColumn>
          <FlexColumn>
            <Link
              href={'https://www.heycater.com/de/catering-duesseldorf'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.duesseldorf')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/catering-koeln'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.cologne')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/catering-leipzig'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.leipzig')}</Subtitle>
            </Link>
            <Link
              href={'https://www.heycater.com/de/catering-hannover'}
              target="_blank"
            >
              <Subtitle>{t('cateringsSection.hannover')}</Subtitle>
            </Link>
          </FlexColumn>
        </Container>
      </Box>
      <Box>
        <Title>{t('cateringsSection.country')}</Title>
        <FlexColumn>
          <Link
            href={'https://www.heycater.com/de/catering-oesterreich'}
            target="_blank"
          >
            <Subtitle>{t('cateringsSection.austria')}</Subtitle>
          </Link>
          <Link
            href={'https://www.heycater.com/de/catering-deutschland'}
            target="_blank"
          >
            <Subtitle>{t('cateringsSection.germany')}</Subtitle>
          </Link>
          <Link
            href={'https://www.heycater.com/de/catering-schweiz'}
            target="_blank"
          >
            <Subtitle>{t('cateringsSection.switzerland')}</Subtitle>
          </Link>
        </FlexColumn>
      </Box>
    </Wrapper>
  );
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 27.6px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const Subtitle = styled(Typography)`
  font-size: 14px;
  line-height: 23.52px;
  color: #3c4340;
  text-decoration: none;
  cursor: pointer;
`;
const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)}px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 1002px;
    padding: 0;
  }
`;
